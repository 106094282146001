import * as React from "react"
import { StyledHeader, TwoColumn } from "../components/Elements"
import { PageWrapper } from "../components/PageWrapper"
import { Section } from "../components/Section"
import { StickyInfo } from "../components/StickyInfo"

// markup
const ImprintPage = () => {
  return (
    <PageWrapper seo={{
        title: 'Imprint'
    }}>
        <Section spacing={['mt-2']}>
          <StyledHeader>
                <h1 className="display">
                    Imprint
                </h1>
                <div className="right font-size sm">
                </div>
          </StyledHeader>
        </Section>
        <Section>
            <StickyInfo left="Information" />
            <div className="grid layout-1-2">
                <div className="left">
                    <div className="sticky-title"></div>
                </div>
                <div className="right">
                    <TwoColumn>
                        <div className="font-size md">
                            If you have any questions, just send us an email to hello[at]designkeepsmealive.com. We are happy to take the time to answer everything. If there is still anything unclear, we are happy to give you a call.
                        </div>
                        <div className="font-size md">
                            <div>
                                Sole proprietorship: Kajetan Powolny <br />
                                Object of the company: Services in automatic data processing and information technology <br />
                            </div>
                            <div style={{ marginTop: '3rem' }}>
                                Address: Zwinzstraße 5/1, 1160, Vienna, AT <br />
                                Telephone number: 0699 19211400 <br />
                                Email: office@fachraum.at <br />
                            </div>
                            <div style={{ marginTop: '3rem' }}>
                                UID number UID number: ATU76524646 <br />
                                GISA number: 31498906 <br />
                                Membership in the Chamber of Commerce organization: Member of the WKÖ, WKNÖ <br />
                            </div>
                            <div style={{ marginTop: '3rem' }}>
                                Trade regulations: www.ris.bka.gv.at, Free Trade <br />
                                Consumers have the opportunity to submit complaints to the EU's online dispute settlement platform: http://ec.europa.eu/odr. You can also send any complaints to the e-mail address given above.
                            </div>
                        </div>
                    </TwoColumn>
                </div>
            </div>
        </Section>
    </PageWrapper>
  )
}

export default ImprintPage
